
import { defineComponent, ref, onMounted, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
export default defineComponent({
  name: "add-role",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const dataPermissionView = ref({});
    const listDataPermissionView = {};
    const listDataPermissions = ref({});

    dataPermissionView.value = listDataPermissionView;
    const validateSchema = Yup.object().shape({
      name: Yup.string().required("Nhóm quyền không được để trống"),
    });
    const onSubmit = (values) => {
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      values.permissions = listDataPermissions.value;
      values.permissions_booking = dataPermissionView.value;
      store
        .dispatch("ACTION_ADD_ROLE", values)
        .then(() => {
          Swal.fire({
            text: "Tạo mới thành công",
            icon: "success",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Danh sách",
            cancelButtonText: "Đóng",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
              cancelButton: "btn fw-bold btn-light-primary",
            },
          }).then(async function (result) {
            if (result.isConfirmed) {
              await router.push({ name: "roles" });
            } else if (result.isDismissed) {
              let data = await store.getters["currentRole"];
              await router.push({
                name: "roles-edit",
                params: { id: data.data._id },
              });
            }
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Thử lại!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      submitButton.value?.removeAttribute("data-kt-indicator");
    };
    const listDataPermissionHTML = ref();
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Thêm mới quyền", ["Người dùng"]);
      MenuComponent.reinitialization();
      await store.dispatch("GET_PERMISSIONS_DATA", "");
      const listData = computed(() => store.getters["listDataPermissions"]);
      listDataPermissionHTML.value = JSON.parse(JSON.stringify(listData.value));
      listData.value.forEach((value, index) => {
        listDataPermissions.value[value._id] = false;
      });
    });
    return {
      onSubmit,
      listDataPermissions,
      dataPermissionView,
      listDataPermissionHTML,
      submitButton,
      validateSchema,
    };
  },
});
